@import '../../../styles/customMediaQueries.css';


.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 3rem;
    height: auto;

    @media (--viewportMedium) {
        width: 18%;
        margin-bottom: 0;
    }
}

.cardLabel {
    font-size: 36px;
    color: #0B1B36;
}

.cardImageWrapper {
    margin-bottom: 2rem;
    width: 100%;
    height: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
    border-radius: 25px;

    @media (--viewportMedium) {
        aspect-ratio: unset;
    }
}

.cardImage {
    width: 100%;
}