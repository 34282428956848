@import '../../../../styles/customMediaQueries.css';


.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    padding-bottom: 2rem;
    gap: 2rem;

    @media (--viewportMedium) {
        flex-direction: row;
        gap: 15%;
        margin-bottom: 0;
    }
}

.reverseInDesktop {
    @media (--viewportMedium) {
        flex-direction: row-reverse;
    }
}

.contentWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;


    @media (--viewportMedium) {
        width: 50%;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
    }
}

.cardLabel {
    font-size: 48px;
    line-height: 56px;
    font-weight: bold;
    color: #0B1B36;
}

.cardImageWrapper {
    width: 100%;
    height: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (--viewportMedium) {
        width: 50%;
    }
}

.cardImage {
    width: 100%;
}

.countLabel {
    width: 60px;
    line-height: 60px;
    border-radius: 50%;
    font-weight: 900;
    border-radius: 50%;
    background-color: #00C986;
    color: white;
    text-align: center;
    font-size: 36px;
    font-family: 'Londrina Solid';
    margin-bottom: 1.5rem;
}