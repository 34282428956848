@import '../../styles/customMediaQueries.css';


.newsletterWrapper {
    position: absolute;
    width: 90vw;
    top: 75px;
    left: calc(50% - 45vw);
    background-color: #1AEFFF;
    padding: 2rem 4rem;
    border-radius: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (--viewportMedium) {
        width: 70vw;
        left: calc(50% - 35vw);
    }
}

.formWrapper {
    position: relative;
    height: 66px;
    width: 100%;
    margin-top: 2rem;

    @media (--viewportMedium) {
        width: 460px;
    }
}

.emailInput {
    position: absolute;
    width: 100%;
    height: 66px;
    padding-right: 60px;
    z-index: 1;
}

.submitButton {
    background-color: #1AEFFF;
    border-radius: 6px;
    width: 54px;
    height: 54px;
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover,
    &:focus {
        background-color: #1AEFFF;
    }

}

.subtitle {
    padding: 10px 16px 10px 16px;
    text-transform: uppercase;
    border: 1px solid #0B1B36;
    border-radius: 6px;
    font-weight: bold;
}

.title {
    font-size: 48px;
    line-height: 50px;
    text-align: center;
}

.description {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    color: #6B6B6B;
}

.spinner {
    stroke: white;
}

.error {
    color: red;
    margin-top: 4px;
}

.success {
    margin-top: 4px;
}