@import '../../styles/customMediaQueries.css';

.root {}

.heroSection {
  background-color: #3BAA7F;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 24px;
  width: 100vw;

  @media (--viewportMedium) {
    padding: 200px 24px 100px 24px;

  }
}

.twoPanelView {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  max-width: 1440px;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;

  }
}

.socialEnterpriseTwoPanelView {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1440px;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;

  }
}

.heroContent {
  color: #FFFFFF;


  h1 {
    font-size: 60px;
    line-height: 68px;
    margin-top: 0;
    font-family: 'Gilroy-Heavy';
    font-weight: normal;
  }

  p {
    font-size: 18px;
  }

  .heroActionButton {
    color: #3BAA7F;
    background-color: #FFFFFF;
    border-radius: 50px;
    width: fit-content;
    padding: 21px 50px 21px 50px;
    margin-top: 4rem;
    font-family: 'Gilroy-Bold';
    font-weight: normal;
  }

  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (--viewportMedium) {
    width: 50%;
    padding-right: 100px;
    text-align: left;
    align-items: flex-start;

  }
}

.heroVideoWrapper {
  border-radius: 50px;
  overflow: hidden;
  width: 100%;
  margin-bottom: 4rem;

  @media (--viewportMedium) {
    width: 50%;
    margin-bottom: 0;
  }
}

.heroVideo {
  width: 100%;
}

.section {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: 100px 24px;

  .contentWrapper {
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    h2 {
      font-size: 60px;
      font-size: 60px;
      line-height: 68px;
      margin-top: 0;
      font-family: 'Gilroy-Bold';
      font-weight: normal;
    }

    p {
      color: #6B6B6B;
      font-size: 18px;
      margin-bottom: 4rem;
    }
  }

  text-align: center;

  @media (--viewportMedium) {
    padding: 100px 50px;
  }
}

.videoBackground {
  width: 100%;
  max-width: 1140px;
}

.categoryWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  font-size: 36px;
  line-height: 44px;

  @media (--viewportMedium) {
    margin-bottom: 4rem;
    flex-direction: row;
    justify-content: space-between;
  }
}

.socialEnterprise {
  color: #54C499;

  h2 {
    font-size: 60px;
    line-height: 68px;
    margin-top: 0;
    font-family: 'Gilroy-Bold';
    font-weight: normal;
  }

  p {
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
  }

  span {
    display: block;
    color: #6B6B6B;
    font-size: 20px;
    line-height: 28px;
  }

  .actionButton {
    border-radius: 50px;
    width: fit-content;
    padding: 21px 50px 21px 50px;
    margin-top: 4rem;
  }

  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (--viewportMedium) {
    width: 50%;
    padding-left: 100px;
    text-align: left;
    align-items: flex-start;

  }
}

.socialEnterpriseImageWrapper {
  border-radius: 50px;
  overflow: hidden;
  width: 100%;
  margin-bottom: 4rem;
  padding: 2rem;

  @media (--viewportMedium) {
    width: 50%;
    margin-bottom: 0;
  }
}

.newsLetterSection {
  position: relative;
  height: 200px;
  width: 100%;
  display: block;
}

.aForceForGoodVideoWrapper {
  border-radius: 8px;
  overflow: hidden;

  @media (--viewportMedium) {
    border-radius: 25px;
  }
}