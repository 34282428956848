@import '../../../../styles/customMediaQueries.css';

.card {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.14) 0%, rgba(0, 169, 213, 0) 100%);
    border-radius: 50px;
    box-shadow: 0px 4px 96px 0px #0000000F;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @media (--viewportMedium) {}
}

.cardImage {
    width: 103%;
    margin-left: -3%;
    margin-bottom: 1rem;
}

.contentWrapper {
    padding: 1rem;
}

.cardLabel {
    font-size: 30px;
    font-weight: bold;
    line-height: 30px;
    text-align: center;

}

.description {
    font-size: 18px;
    margin: 1rem 0;
    display: block;
    color: #6B6B6B;
}