@import '../../../styles/customMediaQueries.css';

.root {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3rem;

    @media (--viewportMedium) {
        flex-direction: row;
        gap: 1.5rem;
    }
}