@import '../../../../styles/customMediaQueries.css';


.card {
    width: 30%;
    padding: 0 1rem;
    margin: 0.5rem 0;

    img {
        margin: auto;
        max-width: 100%;
        width: 100%;
    }

    @media (--viewportMedium) {
        width: 16%;

        img {}
    }
}