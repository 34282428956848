@import '../../../styles/customMediaQueries.css';

.root {
    position: relative;
}

.howItWorksCardWrapper {
    @media (--viewportMedium) {
        max-width: 1140px;
    }
}

.divider {
    @media (--viewportMedium) {
        height: 100%;
        background-image: linear-gradient(#6B6B6B 50%, #6b6b6b00 0%);
        background-position: left;
        background-size: 1px 20px;
        background-repeat: repeat-y;
        position: absolute;
        top: 0;
        width: 2px;
        left: 50%;
    }
}

.startCircle {
    @media (--viewportMedium) {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: #00C986;
        position: absolute;
        top: 0px;
        left: calc(50% - 12px);
    }
}

.endCircle {
    @media (--viewportMedium) {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: #00C986;
        position: absolute;
        bottom: 0px;
        left: calc(50% - 12px);
    }
}